<template>
    <section class="position-relative pt-40 pb-40 bg-dark bg-cover bg-size--cover" style="background-image:url(https://images.unsplash.com/photo-1549082984-1323b94df9a6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)">
        <!-- Overlay -->
        <span class="position-absolute top-0 start-0 w-full h-full bg-dark opacity-80"></span>
        <!-- Content -->
        <div class="container-lg max-w-screen-xl position-relative overlap-10 text-center text-lg-start pt-5 pb-5 pt-lg-6">
            <div class="row row-grid align-items-center">
                <div class="col-lg-12 text-center text-lg-start">
                    <!-- Title -->
                    <h1 class="ls-tight font-bolder display-4 text-white mb-5 shadow-1">
                        Polycle /ˈpɒlik(ə)l/ - A multi-perspective lens.
                    </h1>
                    <h2 class="ls-tight font-bolder display-6 text-white mb-5">
                        We are a herd of digital crafts persons, solving complex problems one line-of-code at a time.
                    </h2>
                    <!-- Text -->
                    <p class="lead text-white mb-10">
                        Share your big plans with us - Let's help you bring those freshly baked ideas to life, and re-engineer the old ones to help you grow and succeed.
                    </p>
                    <!-- Buttons -->
                    <div class="mt-10 mx-n2">
                        <a href="#services" class="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                            Our services <i class="bi bi-arrow-right"></i>
                        </a>
                        <a href="#contact" class="btn btn-lg btn-neutral mx-2 px-lg-8">
                            Contact us <i class="bi bi-envelope"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Hero'
    }
</script>
