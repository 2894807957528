<template>
    <footer id="contact" class="py-20 shadow bg-surface-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-auto">
                    <img src="../../assets/images/polycle-mini.png" alt="Polycle logo">
                </div>
            </div>
            <div class="row mt-10 mb-7">
                <div class="col">
                    <ul class="nav justify-content-center">
                        <li class="nav-item">
                            <a class="nav-link text-md link-primary" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-md link-primary" href="#values">Values</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-md link-primary" href="#milestones">Milestones</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-md link-primary" href="#services">Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-md link-primary" href="#testimonials">Testimonials</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link text-md link-primary" href="#contact">Get in touch</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row justify-content-center text-center">
                <div class="col-xl-9">
                    <p class="text-md text-white-50">
                        <a href="mailto:daveabiola@gmail.com">daveabiola@gmail.com</a>
                    </p>
                </div>
            </div>

            <div class="row justify-content-center text-center mt-10">
                <div class="col-xl-9">
                    <p class="text-sm text-white-50">
                        &copy; Copyright {{ date }} Polycle Innovation Hub Limited - RC1819532
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        computed: {
            date () {
                return (new Date()).getFullYear()
            }
        }
    }
</script>
